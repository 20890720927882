import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const frontmatter = {
  title: 'Build your own Minimal and Scriptable Terminal',
  draft: false,
  date: '2017-10-24T22:58:59.501Z'
};
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  frontmatter,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`I'm a huge fan of `}<a parentName="p" {...{
        "href": "https://sanctum.geek.nz/arabesque/series/unix-as-ide/"
      }}>{`Unix as an IDE`}</a>{` and so the terminal is the core of my computational environment: I run everything from my development tools and text editor to my chat apps and email. There are a variety of terminals out there with their own set of pros and cons, but here we'll find out how to build our own terminal from scratch and to fit our workflow precisely.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Note: This can certainly be adapted to other distros, but for the purposes of this tutorial we'll be focusing on Ubuntu and Archlinux because one is more user friendly and the other is my distro of choice. We're specifically targeting Ubuntu 17.10 and qtermwidget >= 0.7.1`}</p>
    </blockquote>
    <h2>{`Setting up your Machine`}</h2>
    <p>{`Here we'll install the base packages needed to compile and write our basic terminal program. For Ubuntu, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`build-essential`}</code>{` contains the basics for compiling your own C programs. For Archlinux it's called `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`base-devel`}</code>{`.`}</p>
    <p>{`For both distributions we'll be installing qtermwidget: this is a QT based terminal widget that we can place into any QT based application and get most of what we really want out of our terminal application. It'll do most of the heavy lifting so we can focus away from learning about escape codes`}<sup parentName="p" {...{
        "id": "fnref-1"
      }}><a parentName="sup" {...{
          "href": "#fn-1",
          "className": "footnote-ref"
        }}>{`1`}</a></sup>{` and focusing on the appearance of our application.`}</p>
    <p>{`For Ubuntu`}<sup parentName="p" {...{
        "id": "fnref-2"
      }}><a parentName="sup" {...{
          "href": "#fn-2",
          "className": "footnote-ref"
        }}>{`2`}</a></sup></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`apt-get`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`install`}</span>{` build-essential libqtermwidget5-0-dev`}</code></pre></div>
    <p>{`For Archlinux`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` pacman -S base-devel qtermwidget`}</code></pre></div>
    <h2>{`Programming`}</h2>
    <p>{`Let's create a directory to house our project and the c++ file that will hold our code - you can name your terminal whatever you want, but I'm naming mine `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`minimal-term`}</code></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`mkdir`}</span>{` ~/minimal-term
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`cd`}</span>{` ~/minimal-term
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`touch`}</span>{` minimal-term.cpp`}</code></pre></div>
    <p>{`Open `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`minimal-term.cpp`}</code>{` with your editor of choice and add the following code`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "clike"
    }}><pre parentName="div" {...{
        "className": "language-clike"
      }}><code parentName="pre" {...{
          "className": "language-clike"
        }}>{`#include `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`QApplication`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
#include `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`QKeySequence`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
#include `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`QMainWindow`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`

#include `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"qtermwidget.h"`}</span>{`

int `}<span parentName="code" {...{
            "className": "token function"
          }}>{`main`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`int argc`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` char `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`*`}</span>{`argv`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  QApplication `}<span parentName="code" {...{
            "className": "token function"
          }}>{`app`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`argc`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` argv`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  QMainWindow `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`*`}</span>{`mainWindow `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`QMainWindow`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

  QTermWidget `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`*`}</span>{`console `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`QTermWidget`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

  QFont font `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` QApplication`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`font`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  font`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`setFamily`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"Monospace"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  font`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`setPointSize`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`12`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

  console`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`setTerminalFont`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`font`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

  QObject`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`connect`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`console`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`SIGNAL`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`finished`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` mainWindow`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`SLOT`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`close`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

  mainWindow`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`setCentralWidget`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`console`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

  mainWindow`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`show`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` app`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`exec`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`Let's break this down.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "clike"
    }}><pre parentName="div" {...{
        "className": "language-clike"
      }}><code parentName="pre" {...{
          "className": "language-clike"
        }}>{`#include `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`QApplication`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
#include `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`QKeySequence`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
#include `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`QMainWindow`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`

#include `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"qtermwidget.h"`}</span></code></pre></div>
    <p>{`The `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`#include`}</code>{` here is called a directive - it's a way to tell the compiler to do something specific for us at compile time. `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`#include`}</code>{` can be thought of as "include this file at this particular spot in the source code" and can be thought of as the rough equivalent of an import in C++.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "clike"
    }}><pre parentName="div" {...{
        "className": "language-clike"
      }}><code parentName="pre" {...{
          "className": "language-clike"
        }}>{`int `}<span parentName="code" {...{
            "className": "token function"
          }}>{`main`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`int argc`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` char `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`*`}</span>{`argv`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`This is the entry point to our application, and argc and argv represent any arguments passed into our program.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "clike"
    }}><pre parentName="div" {...{
        "className": "language-clike"
      }}><code parentName="pre" {...{
          "className": "language-clike"
        }}>{`  QApplication `}<span parentName="code" {...{
            "className": "token function"
          }}>{`app`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`argc`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` argv`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  QMainWindow `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`*`}</span>{`mainWindow `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`QMainWindow`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

  QTermWidget `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`*`}</span>{`console `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`QTermWidget`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`Here we are creating our Application using Qt using the arguments passed into our main function, a new window to hold our application and instantiating a terminal widget for later on.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "clike"
    }}><pre parentName="div" {...{
        "className": "language-clike"
      }}><code parentName="pre" {...{
          "className": "language-clike"
        }}>{`  QFont font `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` QApplication`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`font`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  font`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`setFamily`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"Monospace"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  font`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`setPointSize`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`12`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

  console`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`setTerminalFont`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`font`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`Here we are defining the font for our terminal to use: we create a new QFont object and give it the font family that we want to use along with the size. Then we call the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`setTerminalFont`}</code>{` method on our terminal widget instance with the QFont object. If you want to know more about the QFont object and all you can do with it, definitely check out the wonderful QT docs`}<sup parentName="p" {...{
        "id": "fnref-3"
      }}><a parentName="sup" {...{
          "href": "#fn-3",
          "className": "footnote-ref"
        }}>{`3`}</a></sup></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "clike"
    }}><pre parentName="div" {...{
        "className": "language-clike"
      }}><code parentName="pre" {...{
          "className": "language-clike"
        }}>{`  mainWindow`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`setCentralWidget`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`console`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  
  QObject`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`connect`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`console`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`SIGNAL`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`finished`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` mainWindow`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`SLOT`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`close`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

  mainWindow`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`show`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` app`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`exec`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`Here we are officially starting our app. First we set the main widget of the main window to be our terminal. Then we "connect" the console and the main window so closing one will close the other. We call the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`show()`}</code>{` method on the main window to see our progress.`}</p>
    <h2>{`Compiling`}</h2>
    <p>{`At this point you should be able to compile your application to see your progress`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`g++ `}<span parentName="code" {...{
            "className": "token variable"
          }}><span parentName="span" {...{
              "className": "token variable"
            }}>{`$(`}</span>{`pkg-config --cflags --libs Qt5Widgets qtermwidget5`}<span parentName="span" {...{
              "className": "token variable"
            }}>{`)`}</span></span>{` -fPIC -o minimal-term minimal-term.cpp`}</code></pre></div>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`pkg-config`}</code>{` here passes the appropriate flags and locations of your libraries so that `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`g++`}</code>{` knows how to properly compile your application. You should then be able to run your application with`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`./minimal-term `}</code></pre></div>
    <blockquote>
      <p parentName="blockquote">{`Note: Whenever I reference "compiling and running" your terminal, come back here to reference these commands`}</p>
    </blockquote>
    <p>{`It won't look very pretty and won't react to any common keybindings. Let's fix that.`}</p>
    <h2>{`Debugging`}</h2>
    <p>{`A quick note here on debugging. There are various handy tools for debugging C++ apps but QT has a built in logger tool that you can use. Let's use it real quick to add a real theme to our terminal.`}</p>
    <p>{`First, add the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`#include <QtDebug>`}</code>{` directive at the top of your file. This will allow us to use qDebug. We'll call a method on our terminal instance to get all of our color schemes and log them out using qDebug`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "clike"
    }}><pre parentName="div" {...{
        "className": "language-clike"
      }}><code parentName="pre" {...{
          "className": "language-clike"
        }}>{`#include `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`QApplication`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
#include `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`QKeySequence`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
#include `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`QMainWindow`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Add the include directive here`}</span>{`

#include `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`QtDebug`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`

#include `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"qtermwidget.h"`}</span>{`

int `}<span parentName="code" {...{
            "className": "token function"
          }}>{`main`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`int argc`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` char `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`*`}</span>{`argv`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  QApplication `}<span parentName="code" {...{
            "className": "token function"
          }}>{`app`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`argc`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` argv`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  QMainWindow `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`*`}</span>{`mainWindow `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`QMainWindow`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

  QTermWidget `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`*`}</span>{`console `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`QTermWidget`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

  QFont font `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` QApplication`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`font`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  font`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`setFamily`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"Monospace"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  font`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`setPointSize`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`12`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

  console`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`setTerminalFont`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`font`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Add the logging statement here`}</span>{`

  `}<span parentName="code" {...{
            "className": "token function"
          }}>{`qDebug`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`" availableColorSchemes:"`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{` console`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`availableColorSchemes`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

  QObject`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`connect`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`console`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`SIGNAL`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`finished`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` mainWindow`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`SLOT`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`close`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

  mainWindow`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`setCentralWidget`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`console`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

  mainWindow`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`show`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` app`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`exec`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`Again, we'll compile and run our application and we should see some output in the terminal of what colorschemes are available. We'll strip out our logging statements now and add the colorscheme to our terminal.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "clike"
    }}><pre parentName="div" {...{
        "className": "language-clike"
      }}><code parentName="pre" {...{
          "className": "language-clike"
        }}>{`#include `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`QApplication`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
#include `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`QKeySequence`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
#include `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`QMainWindow`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`

#include `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"qtermwidget.h"`}</span>{`

int `}<span parentName="code" {...{
            "className": "token function"
          }}>{`main`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`int argc`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` char `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`*`}</span>{`argv`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  QApplication `}<span parentName="code" {...{
            "className": "token function"
          }}>{`app`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`argc`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` argv`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  QMainWindow `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`*`}</span>{`mainWindow `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`QMainWindow`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

  QTermWidget `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`*`}</span>{`console `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`QTermWidget`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

  QFont font `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` QApplication`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`font`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  font`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`setFamily`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"Monospace"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  font`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`setPointSize`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`12`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

  console`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`setTerminalFont`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`font`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Add setting the colorscheme here. Choose whatever one suits your fancy`}</span>{`
  console`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`setColorScheme`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"Solarized"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

  QObject`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`connect`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`console`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`SIGNAL`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`finished`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` mainWindow`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`SLOT`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`close`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

  mainWindow`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`setCentralWidget`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`console`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

  mainWindow`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`show`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` app`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`exec`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <h2>{`Telling others what kind of terminal you're using`}</h2>
    <p>{`At this point you might have noticed that the terminal isn't responding well to input. Some programs output strange characters and others just plain break. We can fix this by telling these programs what kind of terminal we're using. It turns out that qtermwidget shares quite a bit of code with Konsole - the KDE terminal. We'll go ahead and pretend to be Konsole by setting the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`$TERM`}</code>{` environment variable using `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`sentenv()`}</code>{`.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Note: You can substitute other values here like `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`xterm-256color`}</code>{` if you'd like. You can even go crazy and write your own files that tell others about the capabilities of your terminal by running `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`infocmp`}</code>{` to get a sample config from your terminal of choice, editting the resulting output and saving it again by running `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`tic`}</code>{`. That's a bit crazy though.`}</p>
    </blockquote>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "clike"
    }}><pre parentName="div" {...{
        "className": "language-clike"
      }}><code parentName="pre" {...{
          "className": "language-clike"
        }}>{`#include `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`QApplication`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
#include `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`QKeySequence`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
#include `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`QMainWindow`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`

#include `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"qtermwidget.h"`}</span>{`

int `}<span parentName="code" {...{
            "className": "token function"
          }}>{`main`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`int argc`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` char `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`*`}</span>{`argv`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  QApplication `}<span parentName="code" {...{
            "className": "token function"
          }}>{`app`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`argc`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` argv`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  QMainWindow `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`*`}</span>{`mainWindow `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`QMainWindow`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Set the environment variable here`}</span>{`
  `}<span parentName="code" {...{
            "className": "token function"
          }}>{`setenv`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"TERM"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"konsole-256color"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

  QTermWidget `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`*`}</span>{`console `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`QTermWidget`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

  QFont font `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` QApplication`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`font`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  font`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`setFamily`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"Monospace"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  font`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`setPointSize`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`12`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

  console`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`setTerminalFont`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`font`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  console`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`setColorScheme`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"Solarized"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

  QObject`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`connect`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`console`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`SIGNAL`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`finished`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` mainWindow`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`SLOT`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`close`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

  mainWindow`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`setCentralWidget`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`console`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

  mainWindow`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`show`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` app`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`exec`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`Now, after compiling and running your terminal, you should notice that the programs that were previously broken should now work. If they're still glitching, you can try the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`$TERM`}</code>{` value of `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`xterm-256color`}</code>{` since that's something that works for a broad set of terminals, you just might be missing out on some of the power of qtermwidget that xterm doesn't have.`}</p>
    <h2>{`Keybindings`}</h2>
    <p>{`One of the biggest things now missing from our terminal is some basic interactivity. We'll start by adding some keybindings. We'll do this by using Qt's built in "Signals and Slots" which allow us to connect parts of QT almost like legos.`}</p>
    <p>{`In this case, we'll connect Qtermwidget's `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`termKeyPressed`}</code>{` to a lambda function that handles the keybinding and calls appropriate methods for us. We'll start with copying text in our terminal and we'll use the standard `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Ctrl+C`}</code>{` sequence of keys that we're used to from everywhere else`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "clike"
    }}><pre parentName="div" {...{
        "className": "language-clike"
      }}><code parentName="pre" {...{
          "className": "language-clike"
        }}>{`#include `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`QApplication`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
#include `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`QKeySequence`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
#include `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`QMainWindow`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`

#include `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"qtermwidget.h"`}</span>{`

int `}<span parentName="code" {...{
            "className": "token function"
          }}>{`main`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`int argc`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` char `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`*`}</span>{`argv`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  QApplication `}<span parentName="code" {...{
            "className": "token function"
          }}>{`app`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`argc`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` argv`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  QMainWindow `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`*`}</span>{`mainWindow `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`QMainWindow`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token function"
          }}>{`setenv`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"TERM"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"konsole-256color"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

  QTermWidget `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`*`}</span>{`console `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`QTermWidget`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

  QFont font `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` QApplication`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`font`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  font`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`setFamily`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"Monospace"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  font`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`setPointSize`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`12`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

  console`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`setTerminalFont`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`font`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  console`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`setColorScheme`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"Solarized"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`


  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Here we'll allow copying text`}</span>{`
  QObject`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`connect`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`console`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`QTermWidget`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`termKeyPressed`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` mainWindow`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
                   `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`const QKeyEvent `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`*`}</span>{`key`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` void `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
                     `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`key`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`matches`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`QKeySequence`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`Copy`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
                       console`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`copyClipboard`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
                     `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
                   `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

  QObject`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`connect`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`console`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`SIGNAL`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`finished`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` mainWindow`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`SLOT`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`close`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

  mainWindow`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`setCentralWidget`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`console`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

  mainWindow`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`show`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` app`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`exec`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`This bit of code just checks if the keys being pressed match a known Copy sequence embedded in Qt and calls the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`copyClipboard`}</code>{` method on our terminal widget. If you compile and run it, you'll see that you can highlight text with your mouse and copy it using `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Ctrl+C`}</code>{` - just be careful since that sequence `}<em parentName="p">{`also`}</em>{` closes the running program in the terminal.`}</p>
    <p>{`From here we'll go ahead and add pasting too.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "clike"
    }}><pre parentName="div" {...{
        "className": "language-clike"
      }}><code parentName="pre" {...{
          "className": "language-clike"
        }}>{`#include `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`QApplication`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
#include `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`QKeySequence`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
#include `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`QMainWindow`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`

#include `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"qtermwidget.h"`}</span>{`

int `}<span parentName="code" {...{
            "className": "token function"
          }}>{`main`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`int argc`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` char `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`*`}</span>{`argv`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  QApplication `}<span parentName="code" {...{
            "className": "token function"
          }}>{`app`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`argc`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` argv`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  QMainWindow `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`*`}</span>{`mainWindow `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`QMainWindow`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token function"
          }}>{`setenv`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"TERM"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"konsole-256color"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

  QTermWidget `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`*`}</span>{`console `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`QTermWidget`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

  QFont font `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` QApplication`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`font`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  font`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`setFamily`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"Monospace"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  font`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`setPointSize`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`12`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

  console`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`setTerminalFont`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`font`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  console`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`setColorScheme`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"Solarized"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`


  QObject`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`connect`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`console`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`QTermWidget`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`termKeyPressed`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` mainWindow`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
                   `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`const QKeyEvent `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`*`}</span>{`key`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` void `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
                     `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`key`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`matches`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`QKeySequence`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`Copy`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
                       console`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`copyClipboard`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
                       `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Now we can paste too`}</span>{`
                     `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`else`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`key`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`matches`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`QKeySequence`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`Paste`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
                       console`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`pasteClipboard`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
                     `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
                   `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

  QObject`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`connect`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`console`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`SIGNAL`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`finished`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` mainWindow`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`SLOT`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`close`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

  mainWindow`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`setCentralWidget`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`console`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

  mainWindow`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`show`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` app`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`exec`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`While it's nice to have that, I find it even cooler to tie into other parts of our desktop. We'll demonstrate this by giving our terminal the ability to navigate in the browser to links we click on. Start by adding the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`#include <QDesktopServices>`}</code>{` directive to the top of your file and define a method called `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`activateUrl`}</code>{` like so`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "clike"
    }}><pre parentName="div" {...{
        "className": "language-clike"
      }}><code parentName="pre" {...{
          "className": "language-clike"
        }}>{`void `}<span parentName="code" {...{
            "className": "token function"
          }}>{`activateUrl`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`const QUrl `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`url`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` bool fromContextMenu`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`QApplication`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`keyboardModifiers`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{` Qt`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`ControlModifier `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`||`}</span>{`
      fromContextMenu`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    QDesktopServices`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`openUrl`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`url`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`This will allow us to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Ctrl+Click`}</code>{` on any URL in our terminal and have it open in a terminal. We'll need to connect it to the QtermWidget signal called `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`urlActivated`}</code>{`. `}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "clike"
    }}><pre parentName="div" {...{
        "className": "language-clike"
      }}><code parentName="pre" {...{
          "className": "language-clike"
        }}>{`QObject`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`connect`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`console`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`QTermWidget`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`urlActivated`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` mainWindow`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
          activateUrl`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`So our final code should look like:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "clike"
    }}><pre parentName="div" {...{
        "className": "language-clike"
      }}><code parentName="pre" {...{
          "className": "language-clike"
        }}>{`#include `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`QApplication`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Add QDesktopServices`}</span>{`
#include `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`QDesktopServices`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
#include `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`QKeySequence`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
#include `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`QMainWindow`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`

#include `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"qtermwidget.h"`}</span>{`


`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Here we define the activateUrl method`}</span>{`
void `}<span parentName="code" {...{
            "className": "token function"
          }}>{`activateUrl`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`const QUrl `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`url`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` bool fromContextMenu`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`QApplication`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`keyboardModifiers`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{` Qt`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`ControlModifier `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`||`}</span>{`
      fromContextMenu`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    QDesktopServices`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`openUrl`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`url`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

int `}<span parentName="code" {...{
            "className": "token function"
          }}>{`main`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`int argc`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` char `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`*`}</span>{`argv`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  QApplication `}<span parentName="code" {...{
            "className": "token function"
          }}>{`app`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`argc`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` argv`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  QMainWindow `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`*`}</span>{`mainWindow `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`QMainWindow`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token function"
          }}>{`setenv`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"TERM"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"konsole-256color"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

  QTermWidget `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`*`}</span>{`console `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`QTermWidget`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

  QFont font `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` QApplication`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`font`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  font`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`setFamily`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"Monospace"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  font`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`setPointSize`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`12`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

  console`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`setTerminalFont`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`font`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  console`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`setColorScheme`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"Solarized"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  console`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`setTerminalOpacity`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0.9`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Here we connect it all together`}</span>{`
  QObject`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`connect`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`console`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`QTermWidget`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`urlActivated`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` mainWindow`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
                   activateUrl`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

  QObject`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`connect`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`console`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`QTermWidget`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`termKeyPressed`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` mainWindow`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
                   `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`const QKeyEvent `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`*`}</span>{`key`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` void `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
                     `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`key`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`matches`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`QKeySequence`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`Copy`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
                       console`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`copyClipboard`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
                     `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`else`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`key`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`matches`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`QKeySequence`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`Paste`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
                       console`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`pasteClipboard`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
                     `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
                   `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

  QObject`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`connect`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`console`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`SIGNAL`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`finished`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` mainWindow`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`SLOT`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`close`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`


  mainWindow`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`setCentralWidget`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`console`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

  mainWindow`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`show`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` app`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`exec`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <h2>{`Other small tweaks`}</h2>
    <p>{`At this point we should have an extremely minimal terminal that we can shape to our heart's desires. This is how I personally use it, but there's still so much more that can be accomplished with Qt and I'll go through them here at a somewhat faster pace.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "clike"
    }}><pre parentName="div" {...{
        "className": "language-clike"
      }}><code parentName="pre" {...{
          "className": "language-clike"
        }}>{`console`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`setTerminalOpacity`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0.9`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`We can set the transparency of our terminal using the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`setTerminalOpacity`}</code>{` set to any value between 0 and 1.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "clike"
    }}><pre parentName="div" {...{
        "className": "language-clike"
      }}><code parentName="pre" {...{
          "className": "language-clike"
        }}>{`QMenuBar `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`*`}</span>{`menuBar `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`QMenuBar`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`mainWindow`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
QMenu `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`*`}</span>{`actionsMenu `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`QMenu`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"Actions"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` menuBar`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
menuBar`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`addMenu`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`actionsMenu`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
actionsMenu`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`addAction`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"Find.."`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` console`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`SLOT`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`toggleShowSearchBar`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`QKeySequence`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`Qt`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`CTRL `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` Qt`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`SHIFT `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` Qt`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`Key_F`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
actionsMenu`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`addAction`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"About Qt"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`app`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`SLOT`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`aboutQt`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
mainWindow`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`setMenuBar`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`menuBar`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`We can define custom menus from with Qt to display with our terminal. Here we add a couple actions to show a search bar and a little extra information about Qt`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "clike"
    }}><pre parentName="div" {...{
        "className": "language-clike"
      }}><code parentName="pre" {...{
          "className": "language-clike"
        }}>{`console`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`setScrollBarPosition`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`QTermWidget`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`ScrollBarRight`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`We can set the position of the scroll bar (if we want one).`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "clike"
    }}><pre parentName="div" {...{
        "className": "language-clike"
      }}><code parentName="pre" {...{
          "className": "language-clike"
        }}>{`mainWindow`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`resize`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`600`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`400`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`We can resize our main window to whatever size we want.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "clike"
    }}><pre parentName="div" {...{
        "className": "language-clike"
      }}><code parentName="pre" {...{
          "className": "language-clike"
        }}>{`foreach `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`QString arg`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` QApplication`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`arguments`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  qDebug `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{` arg`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`We can iterate over the arguments given to our terminal and do something with them.`}</p>
    <h2>{`Add it to my launcher`}</h2>
    <p>{`At this point you're probably wondering how to add this to your application launcher like a `}<em parentName="p">{`real application`}</em>{`. The first thing to do is compile your application, then we'll add it to your `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`$PATH`}</code>{`. The `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`$PATH`}</code>{` variable tells your Linux distribution where app your application binaries are and Ubuntu/Debian based distributions will automatically put your `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`~/bin`}</code>{` directory in your path if it exists. Let's move our terminal there for now`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`mkdir`}</span>{` ~/bin
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`cp`}</span>{` minimal-term ~/bin`}</code></pre></div>
    <p>{`If you're on Archlinux, a non-Debian distro or this isn't getting picked up properly, just add the following to your `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`~/.profile`}</code>{` file`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{` -d `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"`}<span parentName="span" {...{
              "className": "token variable"
            }}>{`$HOME`}</span>{`/bin"`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`then`}</span>{`
    PATH`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"`}<span parentName="span" {...{
              "className": "token variable"
            }}>{`$HOME`}</span>{`/bin:`}<span parentName="span" {...{
              "className": "token variable"
            }}>{`$PATH`}</span>{`"`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`fi`}</span></code></pre></div>
    <p>{`You should be able to launch another terminal and type `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`minimal-term`}</code>{` to get your new terminal to launch. Obviously that won't do! Launching a terminal to launch a terminal isn't the best workflow. We'll get this from your `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`$PATH`}</code>{` and into your launcher.`}</p>
    <p>{`Create a desktop file in `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`~/local/share/applications`}</code>{` like so:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`touch`}</span>{` ~/.local/share/applications/minimal-term.desktop`}</code></pre></div>
    <p>{`Open your editor of choice and enter the following into the desktop file:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`#!/usr/bin/env xdg-open
[Desktop Entry]
Name=Minimal Terminal
Comment=Use the command line
Exec=minimal-term
Icon=utilities-terminal
Type=Application
Categories=QT;System;TerminalEmulator;
StartupNotify=true`}</code></pre></div>
    <p>{`After restarting your computer, you should see your application in your launcher along side your other terminals. If you want to tweak this file, the basic overview is:`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`Name`}</code>{` - the name of the application that you're defining
`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Comment`}</code>{` - Some additional information on the application
`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Exec`}</code>{` - how to launch the application
`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Icon`}</code>{` - The icon used. Here we're using the built in icon for terminals`}</p>
    <p>{`The rest are more advanced option that you can find out more about in `}<a parentName="p" {...{
        "href": "https://cogitooverdose.wordpress.com/2016/02/21/how-to-write-desktop-files-linux/"
      }}>{`this article from Cogito Overdose`}</a></p>
    <p>{`You might have to change the line that says `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Exec`}</code>{` to point to the local filesystem path of your binary if it's not getting picked up:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`Exec=/path/to/minimal-term`}</code></pre></div>
    <h2>{`Conclusion`}</h2>
    <p>{`When you live in the terminal all day, it becomes worth it to have something completely custom that matches your workflow. Hopefully you've become empowered to build just that.`}</p>
    <h2>{`Extra Credits`}</h2>
    <ol>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://github.com/lxde/qtermwidget/blob/master/example/main.cpp"
        }}>{`This is largely adapted from the official Qtermwidget example over at their Github repository`}</a></li>
      <li parentName="ol">{`I largely stole `}<a parentName="li" {...{
          "href": "https://github.com/thestinger/termite/blob/master/termite.desktop"
        }}>{`Termite's desktop file for minimal-term's`}</a></li>
    </ol>

    <div {...{
      "className": "footnotes"
    }}>
      <hr parentName="div"></hr>
      <ol parentName="div">
        <li parentName="ol" {...{
          "id": "fn-1"
        }}><a parentName="li" {...{
            "href": "http://wiki.bash-hackers.org/scripting/terminalcodes"
          }}>{`http://wiki.bash-hackers.org/scripting/terminalcodes`}</a><a parentName="li" {...{
            "href": "#fnref-1",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
        <li parentName="ol" {...{
          "id": "fn-2"
        }}>{`Thanks to `}<a parentName="li" {...{
            "href": "https://github.com/nickdesaulniers"
          }}>{`Nick Desaulniers`}</a>{` who noticed I was missing the `}<a parentName="li" {...{
            "href": "https://github.com/DonnieWest/donniewest.com/issues/1"
          }}>{`dev package for Ubuntu`}</a><a parentName="li" {...{
            "href": "#fnref-2",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
        <li parentName="ol" {...{
          "id": "fn-3"
        }}><a parentName="li" {...{
            "href": "http://doc.qt.io/qt-5/qfont.html"
          }}>{`http://doc.qt.io/qt-5/qfont.html`}</a><a parentName="li" {...{
            "href": "#fnref-3",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
        <li parentName="ol" {...{
          "id": "fn-4"
        }}><a parentName="li" {...{
            "href": "https://doc.qt.io/qt-5/signalsandslots.html"
          }}>{`https://doc.qt.io/qt-5/signalsandslots.html`}</a><a parentName="li" {...{
            "href": "#fnref-4",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
      </ol>
    </div>
    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      